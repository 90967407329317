import React from 'react'
import PageWrapper from "../components/PageWrapper";
import Related from "../sections/landing/popular.js";
import Find from '../sections/nose/nosefind.js';
import Head from "../components/head";
import { JsonLd } from '../components/JsonLd';
import Hero from "../sections/conditions/backacne/bacnehero.js";
import Causes from "../sections/conditions/backacne/bacnecauses.js";
import Sym from "../sections/conditions/backacne/bacnesym.js";
import AcneTreat from "../sections/conditions/backacne/bacnetreat.js";
import Testimonial from "../sections/reviews/nosereviews.js";
import Saveface from "../sections/landing/saveface";
import ReviewsD from "../sections/nose/nosedesktopreviews.js";
import TwoColumnLayout from '../shared/TwoColumnLayout.js';
import MainImage from '../assets/image/jpeg/active-acne.jpg';
import imgCustomer5 from "../assets/image/jpeg/extract1.jpg";
import imgCustomer6 from "../assets/image/jpeg/about-d.jpg";
import imgCustomer7 from "../assets/image/jpeg/hydra.jpg";
import { Section, Box, Text, Title } from "../components/Core";
import FeatureCard from '../components/Conditions.js';
import styled from "styled-components";
import {Col, Row, Container} from 'react-bootstrap';

const Secondtext = styled.h2`

transform: translate3d(0%, 0px, 0px) scale3d(1, 1, 1) rotateX(
  0deg) rotateY(
  0deg) rotateZ(
  0deg) skew(
  0deg, 
  0deg);
      transform-style: preserve-3d;

      font-size: 2.2em;
      font-smooth: always;
      margin-bottom: 24px;
     
      color: #fff;
 
      font-weight: 100;
      letter-spacing: 2px;
      @media screen and (max-width: 767px)
     {
          margin-bottom: 24px;
          font-size: 24px;
          text-align:center;
      }

`;

const titleText = "Back Acne";
const textContent = "";

const BackAnePage = () => ( 
  <>
 <Head title="Back Acne Treatment in London UK" image="/images/dermamina-home.jpg"  description="Balance or straighten your nose in a same-day appointment. A non-surgical rhinoplasty using dermal fillers is a safe, and non-invasive treatment. " keywords="Nose jobs, Liquid nose job, Liquid rhinoplasty, non Surgical nose job London, Nose filler, Non surgical rhinoplasty, Non surgical nose job, Pain free nose job, non surgical rhinoplasty London, non surgical nose job harley street"/>

<JsonLd>
{{
'@context': 'https://schema.org',
'@type': 'Organization',
'@id': 'https://www.dermamina.com/#organization',
url: 'https://www.dermamina.com',
name: 'Dermamina',
contactPoint: {
  '@type': 'ContactPoint',
  telephone: '020 7099 6650',
  contactType: 'Customer service',
},
address:{
  '@type': 'PostalAddress',
streetAddress: '114 New Cavendish Street',
addressRegion:'London',
postalCode:'W1W 6XT',
addressLocality:'Westminster',
},
image: {
  '@type': 'ImageObject',
  url: 'https://dermamina.com/images/dermamina-home.jpg',   
},
logo: {
'@type': 'ImageObject',
url: 'https://dermamina.com/images/dermamina-logo.svg',   
width: '200',
},
'name': 'dermamina',
'sameAs': [

'https://www.facebook.com/DermaMina-1985252831708400/',
'https://www.instagram.com/dermamina',
],
}}
</JsonLd>

<JsonLd>
{{
'@context': 'https://schema.org',
'@type': 'BreadcrumbList',
'itemListElement': [
  {'@type': 'ListItem', 
position:1,
name:'Dermamina Clinic',
item:'https://www.dermamina.com',
},

{'@type': 'ListItem', 
position:2,
name:'Non-Surgical Rhinoplasty',
item:'https://www.dermamina.com/non-surgical-nose-job',
},

{'@type': 'ListItem', 
position:3,
name:'Tear Trough Fillers Under Eye',
item:'https://www.dermamina.com/tear-trough-under-eye',
},

]
}}
</JsonLd>
      <PageWrapper headerDark footerDark>
    
      <TwoColumnLayout title={titleText} text={textContent} imageData={null} image={MainImage} />
      <AcneTreat />
   <Causes />
   <Sym />
    
  <ReviewsD />
      <Testimonial />
  
     

      <Related />
      <Find />
 
      <Saveface />
      
 
     </PageWrapper>
 
  </>
)
export default BackAnePage
