import React from "react";
import styled from "styled-components";
import Link from "gatsby-link"
import { Container, Row, Col } from "react-bootstrap";
import Slider from "react-slick";
import { rgba } from "polished";

import { Title, Section, Box, Text, Span } from "../../../components/Core";
import { device, breakpoints } from "../../../utils";


import imgCustomer2 from "../../../assets/image/jpeg/micro-hero.jpg";
import imgCustomer3 from "../../../assets/image/jpeg/profhilo-hero.jpg";
import imgCustomer4 from "../../../assets/image/jpeg/dermal-hero-pop.jpg";
import imgCustomer5 from "../../../assets/image/jpeg/tear-hero-pop.jpg";
import imgCustomer6 from "../../../assets/image/jpeg/mes-hero.jpg";
import imgCustomer7 from "../../../assets/image/jpeg/bp.jpg";
import imgCustomer9 from "../../../assets/image/jpeg/anti-w-hero.jpg";
import Himage from '../../../assets/image/jpeg/home-bg2.jpg';
import Newarrow from '../../../assets/image/jpeg/enter.svg'


const Arrow = styled.div`


width: 1.2em;
margin-left: 0.8em;
opacity: 0.6;

`;

const Bookbutton = styled.button`
position: relative;
display: -webkit-box;
display: -webkit-flex;
display: -ms-flexbox;
display: flex;
padding: 0.8em 2.4em;
-webkit-box-align: center;
-webkit-align-items: center;
-ms-flex-align: center;
align-items: center;
font-family: azo-sans-web, sans-serif;
color: #fff;

font-size: 12px;
letter-spacing: 2px;
text-decoration: none;
text-transform: uppercase;
cursor: pointer;
border:none;
background-color: #1a1a1a;
color: #AB8E66;
opacity: 0.8;
transition: 0.3s;
margin-left:20px;
margin-right:20px;
&:hover{
  opacity: 1
}

a{font-size:10px;}


@media screen and (max-width: 767px)
{
    font-size: 8px;
 }
`;

const Secti = styled.section`


position: relative;

background-color: rgb(171, 142, 102);


@media screen and (max-width: 767px)
{
  
 }

`;



const BlogTitle = styled.p`

font-size:18px;
color: #1a1a1a;
font-weight:600;
padding-top:5px;
padding-bottom:5px;
padding-left:20px;
padding-top:20px;
padding-right:20px;

@media screen and (max-width: 991px)
 {
  font-size:12px;
}

`;

const BlogText = styled.p`

font-size:16px;
color: #999;
padding-left:20px;

padding-right:20px;

@media screen and (max-width: 991px)
 {
  font-size:10px;
}

`;

const Imagi = styled.div`

position: absolute;
left: 0%;
top: 0%;
right: 0%;
bottom: 0%;
width: 100%;
height: 100%;
background: url(${Himage}) no-repeat;
background-size: cover;
background-position: center center;
background-position: 0px 0px;

opacity:0.056;


`;

const Iwrap = styled.div`

display: -webkit-box;
display: -webkit-flex;
display: -ms-flexbox;
display: flex;
margin-bottom: 1em;
-webkit-box-align: center;
-webkit-align-items: center;
-ms-flex-align: center;
align-items: center;


@media screen and (max-width: 767px)
{
    margin-bottom: 8px;
    -webkit-box-pack: start;
    -webkit-justify-content: flex-start;
    -ms-flex-pack: start;
    justify-content: flex-start;
}

@media screen and (max-width: 991px)
 {
    margin-bottom: 8px;
    -webkit-box-pack: center;
    -webkit-justify-content: center;
    -ms-flex-pack: center;
    justify-content: center;
    text-align: center;
}


`;



const Iline = styled.div`

width: 6em;
height: 1px;
margin-right: 1em;
background-color: #e5e5ea;

@media screen and (max-width: 991px)
 {
  width: 3em;
}

`;

const Itext = styled.div`

margin-top: 0px;
margin-bottom: 0px;
color: #e5e5ea;
font-size: 14px;
font-weight: 400;


@media screen and (max-width: 991px)
 {
    font-size: 12px;
}

`;


const Secondtext = styled.h2`

transform: translate3d(0%, 0px, 0px) scale3d(1, 1, 1) rotateX(
  0deg) rotateY(
  0deg) rotateZ(
  0deg) skew(
  0deg, 
  0deg);
      transform-style: preserve-3d;

      font-size: 2.2em;
      font-smooth: always;
      margin-bottom: 0.4em;
     
      color: #fff;
 
      font-weight: 100;
      letter-spacing: 2px;
      @media screen and (max-width: 767px)
     {
          margin-bottom: 24px;
          font-size: 24px;
          text-align:center;
      }

`;





const Thirdtext = styled.div`

opacity: 1;
transform: translate3d(0%, 0px, 0px) scale3d(1, 1, 1) rotateX(
0deg) rotateY(
0deg) rotateZ(
0deg) skew(
0deg, 
0deg);
transform-style: preserve-3d;


width: 90%;
color: rgba(255, 255, 255, 0.7);


color: #fff;
font-size: 1em;
line-height: 1.5;


@media screen and (max-width: 767px)
{
     margin-bottom: 24px;
     font-size: 12px;
     text-align:center;
     width: 100%;
 }

`;


const SliderStyled = styled(Slider)`
margin-left:30px;
margin-right:30px;

.slick-dots {
  position: relative;

  li {
    font-size: 0;
    width: 17px;
    height: 8px;
    border-radius: 4px;
    background-color: ${({ theme }) => theme.colors.shadow};
    margin-left: 5px;
    margin-right: 5px;
    margin-top:0px;
    
    transition: 0.5s;
    &.slick-active {
      width: 45px;
      height: 8px;
      border-radius: 4px;
      background-color: rgb(171, 142, 102);
    }
    button {
      width: 100%;
      height: 100%;
      &:before {
        content: none;
      }
    }
  }
}
  position: relative;

  .slick-arrow {
    position: absolute;
    top: 50%;
    font-size: 0;
    height: 20px;
    display: flex;
    margin-left:3px;
    margin-right:3px;
    width: 20px;
    background: #1a1a1a;
    transition: 0.4s;
    border-radius: 500px;
    transform: translate(-50%, -50%);
    z-index: 999;
    margin-top: -20px;
    &::before {
      position: absolute;
      content: "";
      font-family: "Font Awesome 5 Free";
      font-weight: 900;
      font-size: 12px;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      color: #fffbf6;
      transition: 0.4s;
    }
  }
  .slick-arrow.slick-prev {
    left: 0;
    &:before {
      content: "\f060";
    }
    @media ${device.md} {
      /* margin-left: 60px; */
    }
  }

  .slick-arrow.slick-next {
    right: 0;
    left: auto;
    transform: translate(50%, -50%);
    &:before {
      content: "\f061";
    }
    @media ${device.md} {
      /* margin-right: 60px; */
    }
  }

  .slick-arrow:hover,
  .slick-arrow:focus {
    background: #1a1a1a;
    color: #fffbf6;
    &:before {
      color: #fffbf6;
    }
  }
`;

const SliderItem = styled(Box)`
  &:focus {
    outline: none;
  }
`;

const HeroT = styled.h2`
  letter-spacing: 0px;
  font-size: 18px;
  font-weight: 800;
  line-height: 20px;

  color: white;
  margin-bottom: 30px;

  @media ${device.sm} {
    font-size: 66px;
    line-height: 70px;
  }

  @media ${device.lg} {
    font-size: 30px;
    line-height: 40px;
  }

  @media ${device.xl} {
    font-size: 30px;
    line-height: 40px;
  }
`;

const SliderCard = styled(Box)`
  border-radius: 5px;
  background: #f6f6f6;

  

border:none;
  display: flex;
  flex-direction: column;

  padding: 0px;
  @media ${device.sm} {
    padding: 5px 5px 5px 5px;
    background: #ffffff;
  }
  @media ${device.md} {
    padding: 10px;
    flex-direction: row;
  }
  @media ${device.lg} {
    margin: 10px 10px 0px 10px !important;
  }
`;

const ULStyled = styled.ul`

  list-style: none;
  margin: 0;
  padding: 10px 15px 0px;

  li {
    &:nth-child(odd) {
      @media ${device.sm} {
        margin-right: 40px;
      }
    }
    color: #19191b;
    font-size: 12px;
    font-weight: 400;
    letter-spacing: -0.66px;
    line-height: 50px;
    display: flex;
    margin-bottom: 5px;

    &:before {
      content: "\f00c";
      font-family: "Font Awesome 5 Free";
      font-weight: 900;
      display: inline-block;
      font-size: 13px;
      width: 30px;
      min-width: 30px;
      height: 30px;
      background-color: ${({ theme }) => rgba(theme.colors.secondary, 0.1)};
      display: flex;
      align-items: center;
      justify-content: center;
      border-radius: 500px;
      color: ${({ theme }) => theme.colors.secondary};
      position: relative;
      top: 10px;
      margin-right: 13px;
    }
  }
`;

const SliderImgContainer = styled(Box)`

  

padding: 0 0 20px 0;

  @media ${device.sm} {
    min-width: 100%;
    padding: 0;
  }
  @media ${device.md} {
  }

  img {
    margin: 0 auto;
    min-width: 100%;
    width: 100%;
    height: auto;
  max-width: 100%;
  }

  @media ${device.sm} {
    max-width: 100%;
    width: auto;
  }
`;

const SliderText = styled(Box)`
  text-align: left;
  display: block;
 font-size:15px;
 font-weight:700;
  align-items: center;
  justify-content: center;

  padding: 0px 15px 0px;
  @media ${device.md} {
    padding: 0px 10px 0px;
  }
  @media ${device.lg} {
    padding: 0px 10px 0px;
    font-size:20px;
  }
`;

const Items = styled.div`
flex: 0 0 50%; 


@media ${device.md} {
  padding: 20px
}
@media ${device.lg} {
padding:20px;
}
`;

const Center = styled.div`

display: flex;
flex-direction: column;
align-items:center;
height: 100%;
flex-wrap: wrap;
align-items:stretch;

@media ${device.md} {
  flex-direction: row;
}
@media ${device.lg} {
  flex-direction: row;
}

`;


const TextSlider = styled(Box)`
  text-align: left;
  display: block;
 font-size:12px;
 color:black;
  align-items: center;
  justify-content: center;

  padding: 0px 15px 0px;
  @media ${device.md} {
    padding: 0px 10px 0px;
  }
  @media ${device.lg} {
    padding: 0px 10px 0px;
    font-size:16px;
  }
`;
const SliderQuoteShape = styled(Box)`
  display: block;
  justify-content: center;
  align-items: center;
  margin-bottom: 0px;
  @media ${device.md} {
    padding-right: 0px;
    margin-bottom: 0px;
  }
`;

const TreatmentBACnePage = () => {
  const slickSettings = {
    dots: true,
    infinite: true,
   
     
  
    autoplay:true,
    slidesToShow: 1,
    slidesToScroll: 1,
    centerMode: true,
    adaptiveHeight: true,
    centerPadding: "200px",
    speed: 500,
      cssEase: "linear",
    arrows: false,
    responsive: [
      {
        breakpoint: breakpoints.lg,
        settings: {
         
          centerMode: false,
  
          slidesToShow: 1,
          slidesToScroll: 1,
          adaptiveHeight: true,
          arrows: true,
        },
      },
    ],
  };

  return (
    <>
      {/* <!-- testimonial section --> */}
      <Secti>
        
      <Section py={4} bg="rgb(171, 142, 102)">
        <Container className="pt-lg-5">
        <Row className="" >
            <Col data-aos="fade-up"
              data-aos-duration="1000"
              data-aos-once="false" lg="6" md="9">
            <Iwrap>


<Itext as="h2">Back Acne Treatments</Itext>
</Iwrap>
      <Secondtext>
 Treatments for Back Acne
      </Secondtext>
            </Col>
          </Row>
        </Container>
        <Container fluid className="pb-lg-5">
         
          <Row className="justify-content-center align-items-center" >
            <Col data-aos="fade-up"
              data-aos-duration="1500"
              data-aos-once="false">
              <SliderStyled {...slickSettings}>


              <Box p="20px"
    
    css={`
      &:focus {
        outline: none;
      }
    `}
  >
  <SliderItem>
    <SliderCard>
     
      <SliderImgContainer>
      <Center>
        <Items>
        <Link to="https://connect-dermamina.pabau.me/bookings.php?compid=8607&location_id=7387&category=101670&service=2596906"> <img src={imgCustomer7} alt="Non-Surgical Rhinoplasty"/></Link>
        </Items>
        <Items>
        <BlogTitle color="black">Back Peel</BlogTitle>  <br /> 
<BlogText>
A salicylic acid back peel is a cosmetic technique that improves the appearance and texture of the skin on the back. Salicylic acid, a beta-hydroxy acid (BHA), is applied topically to the back, where it exfoliates the dead skin  and unclogs pores.
</BlogText> <br /> 

<Link to="https://connect-dermamina.pabau.me/bookings.php?compid=8607&location_id=7387&category=101670&service=2596906"><Bookbutton>Book Consultation<Arrow>
    <img src={Newarrow} />
  </Arrow>
  </Bookbutton> </Link>
  </Items>
      </ Center>
      </SliderImgContainer>
    
    </SliderCard>
  </SliderItem>
 </Box>
                


    


                
           
            
           
               

              </SliderStyled>
            </Col>
          </Row>
        </Container>
      </Section>
      </Secti>
    </>
  );
};

export default TreatmentBACnePage;
